<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.projname" placeholder="项目名" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-input v-model="listQuery.title" placeholder="链接标题" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-select v-model="listQuery.status" placeholder="状态" clearable style="width: 90px" class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">
        添加
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" sortable="custom" align="center" width="80">
        <template slot-scope="{row}">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.projname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="链接标题" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="回复内容" align="center" width="250px">
        <template slot-scope="{row}">
          <span>{{ row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目状态" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.status | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="链接排序" width="80px">
        <template slot-scope="{row}">
        <span>{{row.sortid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="点击数" width="80px">
        <template slot-scope="{row}">
        <span>{{row.hits}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" align="center" class-name="status-col" width="180">
        <template slot-scope="{row}">
          <span>{{ row.updatetime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center"  fixed="right" width="250" class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button v-if="row.status!='1'" size="mini" type="success" @click="handleModifyStatus(row,'1')">
            禁用
          </el-button>
          <el-button v-if="row.status!='0'" size="mini" @click="handleModifyStatus(row,'0')">
            解禁
          </el-button>
          <el-button v-if="row.status!='deleted'" size="mini" type="danger" @click="handleDelete(row,$index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 500px; margin-left:50px;">
        <el-form-item label="选择项目" prop="projid">
          <el-select v-model="temp.projid" placeholder="请选择项目" clearable style="width: 150px" @change="getMaxSort()">
            <template v-for="pro in projectList">
              <el-option :label="pro.projname" :value="pro.projid"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="链接标题" prop="title">
          <el-input v-model="temp.title" type="text" placeholder="请输入项目名" />
        </el-form-item>
        <el-form-item label="回复内容" prop="content">
          <el-input v-model="temp.content" type="textarea" placeholder="请输入回复内容"/>
        </el-form-item>
        <el-form-item label="链接排序" prop="sortid">
          <el-input v-model="temp.sortid" type="text" placeholder="请输入链接排序"/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {getquicklinks,addorupdatequicklinks,deletequicklinks,updatequicklinksStatus,querymaxsortid} from "@/apis/quick-links";
  import { parseTime } from '@/utils'
  const statusOptions = [
    { key: '0', display_name: '正常' },
    { key: '1', display_name: '禁用' },
  ]
  const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  export default {
    name: 'quicklinks',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return statusMap[status]
      },
      typeFilter(type) {
        return calendarTypeKeyValue[type]
      },
      parseTime:parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
        projectList:[],
        listQuery: {
          page: 1,
          limit: 20,
          title:'',
          status:'',
          projname:'',
          auid:null
        },
        statusOptions: ['published', 'draft', 'deleted'],
        showReviewer: false,
        temp: {
          id: null,
          title: '',
          content: '',
          sortid: '',
          projid: '',
          auid:null
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '修改',
          create: '添加'
        },
        dialogPvVisible: false,
        pvData: [],
        rules: {
          title: [{ required: true, message: '请输入链接标题', trigger: 'change' }],
          projid: [{ required: true, message: '请选择项目', trigger: 'change' }],
          content: [{  required: true, message: '请输入回复内容', trigger: 'change' }],
          sortid: [{ required: true, message: '请输入链接排序', trigger: 'change' }]
        },
        downloadLoading: false
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.auid=this.user.userId
        getquicklinks(this.listQuery).then(res => {
        const {count = 0} = res;
        this.list=res.data.dataList;
        this.projectList=res.data.projectList;
        this.total=count;
        this.listLoading = false;
        })
      },
      getMaxSort(){
        querymaxsortid(this.temp).then(res => {
          this.temp.sortid=res.data+1;
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleModifyStatus(row, status) {
        this.temp = Object.assign({}, row)
        var id=this.temp.id;
        updatequicklinksStatus({id,status}).then(res =>{
          this.$message.success(res.retDesc);
          row.status = status
        })
      },
      resetTemp() {
        this.temp = {
          id: undefined,
          title: null,
          content: null,
          sortid: null,
          projid: null,
          auid:null
        }
      },
      handleCreate() {
        this.resetTemp()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.auid=this.user.userId
            addorupdatequicklinks(this.temp).then(res =>{
              console.log(this.temp)
              this.$message.success(res.retDesc);
              this.dialogFormVisible=false;
              this.getList();
            });
          }
        })
      },
      handleUpdate(row) {
        console.log(row)
        this.temp = Object.assign({}, row) // copy obj
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      updateData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.auid=this.user.userId
            addorupdatequicklinks(this.temp).then(res =>{
              this.$message.success(res.retDesc);
              this.dialogFormVisible=false;
              this.getList();
            });
          }
        })
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      },
      handleDelete(row, index) {
        this.temp = Object.assign({}, row)
        var id=this.temp.id;
        deletequicklinks({id}).then(res =>{
          this.$message.success(res.retDesc);
          this.list.splice(index, 1)
        });
      },
    },computed:{
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '禁用', value: 1},
        {name: '正常', value: 0}
      ]
    },
  }
  }
</script>

<style scoped>
.filter-item {
  margin-left:5px;
}

</style>
